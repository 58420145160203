import RouterLink from "./router";
import GlobalStyle from "./utils/GlobalStyle";
import {useEffect} from "react";
const App = () => {

  return (
    <>
      <RouterLink />
      <GlobalStyle />
    </>
  );
};

export default App;
